export const getOrderNumber = (orderId: string): string => {
  return orderId.slice(-8);
};

export const isMilitaryTimeFormat = (time: string): boolean => {
  const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
  return timeRegex.test(time);
  /*
    isMilitaryTimeFormat('00:00')  // true (midnight)
    isMilitaryTimeFormat('13:30')  // true (1:30 PM)
    isMilitaryTimeFormat('23:59')  // true (11:59 PM)

    isMilitaryTimeFormat('24:00')  // false (hour too high)
    isMilitaryTimeFormat('12:60')  // false (minute too high)
    isMilitaryTimeFormat('9:30')   // false (hour needs leading zero)
    isMilitaryTimeFormat('09:5')   // false (minute needs leading zero)
  */
};

// Validate time range
export const isValidTimeRange = (startTime: string, endTime: string): boolean => {
  return startTime < endTime;
};

export const timeToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};
