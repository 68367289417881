import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { storeThemeSelectors, useThemeStore } from 'stores';
import { getCouponDesign } from 'utils/coupons';
import { ADVERT_LOCATION } from '../../../submodules/sicpama-shared';
import { LOCAL_STORAGE_KEY } from 'constants/browser-storage.constant';
import { ROUTE } from 'constants/route.constant';

function CouponHeader(): JSX.Element {
  const navigate = useNavigate();
  const { store } = useThemeStore();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const storeId = searchParams.get('storeId');

  const coupons = useThemeStore(
    storeThemeSelectors.getCouponByAdvertLocation(ADVERT_LOCATION.LOGIN_PAGE),
  );

  return (
    <div className="w-full overflow-scroll">
      <div
        className="w-8 h-8 rounded-full absolute top-4 right-4 flex items-center justify-center"
        style={{ background: 'rgba(0, 0, 0, 0.05)' }}
      >
        <div
          className="cursor cursor-pointer"
          onClick={() => {
            const from = searchParams.get('from');
            if (from) {
              navigate(-1);
            } else {
              const beforeSignInPath = localStorage.getItem(LOCAL_STORAGE_KEY.BEFORE_SIGN_IN_PATH);
              if (storeId && beforeSignInPath) {
                navigate(beforeSignInPath);
              } else {
                navigate(ROUTE.HOME);
              }
            }
          }}
        >
          <img className="w-[18px] h-[18px]" src="/icons/close-icon.svg" width={18} height={18} />
        </div>
      </div>
      {coupons?.map((coupon, idx) => (
        <div
          key={coupon.id}
          className={`w-full py-2 ${idx < coupons.length - 1 && 'border-b border-gray-200'}`}
          dangerouslySetInnerHTML={{
            __html:
              getCouponDesign({
                coupon,
                store,
                values: {
                  heading1: `${
                    coupon.criteria?.customer?.find((rule) => rule?.stamps)
                      ? t('coupons.signInToEarnStamp')
                      : ''
                  }`,
                  heading2: '',
                },
              }) || `<div></div>`,
          }}
        ></div>
      ))}
    </div>
  );
}

export default CouponHeader;
