export const LOCAL_STORAGE_KEY = {
  MY_ID: 'myId',
  CURRENT_TOKEN: 'currentToken',
  MY_CURRENT_ORDER_ID: 'myCurrentOrderId',
  MOVE_TO_PG_SIDE: 'moveToPGSide',
  FINGERPRINT: 'fingerPrint',
  FINGERPRINT_REQUEST_ID: 'fingerprintRequestId',
  SID: 'sid',
  GUEST_ACCESS_TOKEN: 'guestAccessToken',
  LINKED_WITH_FINGERPRINT: 'customerLinkedWithFingerPrint',
  VIEW_IN_GRID: 'viewInGrid',
  SELECTED_CATEGORY_ID: 'selectedCategoryId',
  MUST_FINISH_SPLIT_EVENLY: 'mustFinishSplitEvenly',
  BEFORE_SIGN_IN_PATH: 'beforeSignInPath',
  ALREADY_CLAIMED_COUPON: 'alreadyClaimedCoupon',
};
