import { ReactElement, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import MenuCarousel from 'pages/menu/components/MenuCarousel/MenuCarousel';
import { getRecommendedMenuIds } from 'pages/menu/components/RecommendationAlgorithm';

import {
  menuSelectors,
  OrderSelectors,
  useMenuStore,
  useOrderStore,
  useThemeStore,
} from '../../stores';
import { Typography } from '@sicpama/core-components';
import { ROUTE } from 'constants/route.constant';

const RecommendationMenusCarousel = (): ReactElement | null => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const menuIds = useMenuStore(menuSelectors.getBaseMenuIds);
  const numberOfCustomer = useOrderStore(OrderSelectors.getNumberOfCustomer);
  const recommendedSystemList = useThemeStore((state) => state.store.menuRecommended ?? []);
  const menuRecommendedCarouselIds = useMemo(
    () => getRecommendedMenuIds(numberOfCustomer, recommendedSystemList, menuIds),
    [numberOfCustomer, recommendedSystemList, menuIds],
  );
  const getMenus = useMenuStore((state) => state.getMenus);

  const { menusByCategories } = useMenuStore();

  useEffect(() => {
    if (!menusByCategories.length && window.location.pathname === ROUTE.TAB) {
      getMenus();
    }
  }, [menusByCategories]);

  if (!menuRecommendedCarouselIds.length) {
    return null;
  }

  return (
    <div className="animate-jump animate-twice">
      <Typography size="lg" className="pl-4 text-theme font-bold">
        {t('common.recommendMenu')?.toUpperCase()}
      </Typography>
      <MenuCarousel
        itemsIds={menuRecommendedCarouselIds}
        onClick={(id) => {
          navigate(`/menus/${id}?from=${window.location.pathname}`);
        }}
      />
    </div>
  );
};

export default RecommendationMenusCarousel;
