import { create } from 'zustand';
import { LanguageService, storeService } from '../services';

interface CouponAdvertState {
  couponAdvertHtml: string;
  isLoading: boolean;
  error: string | null;
  couponClaimedSuccessHtml: string;
  signUpForCouponAdvertHtml: string;
  fetchCouponAdvert: (storeId: string, couponId: string) => Promise<void>;
}

export const useCouponAdvertStore = create<CouponAdvertState>((set) => ({
  couponAdvertHtml: '',
  signUpForCouponAdvertHtml: '',
  couponClaimedSuccessHtml: '<div>Success!</div>',
  isLoading: false,
  error: null,
  fetchCouponAdvert: async (storeId: string, couponId: string) => {
    set({ isLoading: true, error: null });
    try {
      const couponAdvert = await storeService.getStoreCouponAdvert(storeId, couponId);
      const html =
        couponAdvert.advert?.location?.SOCIAL_ADVERT?.[LanguageService.getLanguage() ?? 'en'] ??
        couponAdvert.advert?.location?.MARKETING?.[LanguageService.getLanguage() ?? 'en'] ??
        couponAdvert.advert?.location?.LOGIN_PAGE?.[LanguageService.getLanguage() ?? 'en'] ??
        `<div>NO ADVERT DEFINED</div>`;
      const couponClaimedSuccessHtml =
        couponAdvert.advert?.location?.COUPON_CLAIMED_SUCCESS?.[
          LanguageService.getLanguage() ?? 'en'
        ] ?? '<div>Success!</div>';
      const signUpForCouponAdvertHtml =
        couponAdvert.advert?.location?.SIGNUP_FOR_COUPON?.[LanguageService.getLanguage() ?? 'en'] ??
        '';
      set({
        couponAdvertHtml: html,
        couponClaimedSuccessHtml,
        signUpForCouponAdvertHtml,
        isLoading: false,
      });
    } catch (err: any) {
      set({ error: err.message || 'Failed to fetch coupon advert', isLoading: false });
    }
  },
}));
