/* eslint-disable @typescript-eslint/promise-function-async */
import { Modal, Typography } from '@sicpama/core-components';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '../../stores';

import './styles.scss';

const translationPrefix = 'pages.login';

interface SmallLoginModalProps {
  opened: boolean;
  closeModal: () => void;
  paymentOption: string;
  headerTitle?: string;
  htmlContent?: string;
  loginOptions?: string[];
}

interface BaseLoginButtonProps {
  goToProviderIdSignInUrl: (providerId: string) => void;
}

const loginOptionToIcon: Record<string, string> = {
  google: '/icons/google-rounded-icon.svg',
  facebook: '/icons/facebook-rounded-icon.svg',
  kakao: '/icons/kakao-rounded-icon.svg',
  tiktok: '/icons/tiktok-rounded-icon.svg',
  threads: '/icons/threads-rounded-icon.svg',
};

const BaseLoginButton = ({ goToProviderIdSignInUrl }: BaseLoginButtonProps): JSX.Element => {
  return (
    <div className="mt-4 flex gap-4 justify-center mx-auto">
      <div
        className="flex flex-col items-center"
        onClick={() => {
          goToProviderIdSignInUrl('google');
        }}
      >
        <img
          className="w-[72px] h-[72px]"
          src="/icons/google-rounded-icon.svg"
          width={72}
          height={72}
        />
        <Typography size="sm" weight="bold">
          Google
        </Typography>
      </div>

      <div
        className="flex flex-col items-center"
        onClick={() => {
          goToProviderIdSignInUrl('facebook');
        }}
      >
        <img
          className="w-[72px] h-[72px]"
          src="/icons/facebook-rounded-icon.svg"
          width={72}
          height={72}
        />
        <Typography size="sm" weight="bold">
          Facebook
        </Typography>
      </div>

      <div
        className="flex flex-col items-center"
        onClick={() => {
          goToProviderIdSignInUrl('kakao');
        }}
      >
        <img
          className="w-[72px] h-[72px]"
          src="/icons/kakao-rounded-icon.svg"
          width={72}
          height={72}
        />
        <Typography size="sm" weight="bold">
          KakaoTalk
        </Typography>
      </div>

      <div
        className="flex flex-col items-center"
        onClick={() => {
          goToProviderIdSignInUrl('tiktok');
        }}
      >
        <img
          className="w-[72px] h-[72px]"
          src="/icons/tiktok-rounded-icon.svg"
          width={72}
          height={72}
        />
        <Typography size="sm" weight="bold">
          TikTok
        </Typography>
      </div>
      {process.env.REACT_APP_THREADS_LOGIN_ENABLED && (
        <div
          className="flex flex-col items-center"
          onClick={() => {
            goToProviderIdSignInUrl('threads');
          }}
        >
          <img
            className="w-[72px] h-[72px]"
            src="/icons/threads-rounded-icon.svg"
            width={72}
            height={72}
          />
          <Typography size="sm" weight="bold">
            Threads
          </Typography>
        </div>
      )}
    </div>
  );
};

const SmallLoginModal = ({
  opened,
  closeModal,
  paymentOption,
  headerTitle,
  htmlContent,
  loginOptions,
}: SmallLoginModalProps): JSX.Element => {
  const { goToProviderIdSignInUrl } = useAuthStore();
  const { t } = useTranslation();

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      title={
        <div>
          <Typography size="xl" weight="bold">
            {headerTitle || t(`${translationPrefix}.smallModal.title`)}
          </Typography>
          <img
            className="absolute top-4 right-0 w-8 h-8"
            src="/icons/close-icon.svg"
            width={32}
            height={32}
            onClick={closeModal}
          />
        </div>
      }
      centered
    >
      <div>
        <div className="login-page-wrapper relative flex flex-col items-center justify-center">
          {htmlContent && <div dangerouslySetInnerHTML={{ __html: htmlContent }} />}
          {paymentOption && (
            <Typography size={'lg'} className="mt-2">
              {t(`${translationPrefix}.smallModal.content`, {
                paymentOption,
              })}
            </Typography>
          )}
          {loginOptions ? (
            <div className="mt-4 flex gap-4 justify-center mx-auto">
              {loginOptions.map((option) => (
                <div
                  key={option}
                  className="flex flex-col items-center"
                  onClick={() => {
                    goToProviderIdSignInUrl(option);
                  }}
                >
                  <img
                    className="w-[72px] h-[72px]"
                    src={loginOptionToIcon[option]}
                    width={72}
                    height={72}
                  />
                  <Typography size="sm" weight="bold">
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                  </Typography>
                </div>
              ))}
            </div>
          ) : (
            <BaseLoginButton goToProviderIdSignInUrl={goToProviderIdSignInUrl} />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SmallLoginModal;
