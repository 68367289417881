import { Modal } from '@sicpama/core-components';

interface Props {
  visible: boolean;
  closeModal: () => void;
  htmlContent: string;
}

export const CouponClaimedSuccess = ({ visible, closeModal, htmlContent }: Props): JSX.Element => {
  return (
    <Modal
      opened={visible}
      onClose={closeModal}
      closeOnClickOutside
      withCloseButton={false}
      title=""
      centered
      fullScreen={false}
      styles={() => ({
        body: {
          padding: 0,
        },
      })}
    >
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </Modal>
  );
};
