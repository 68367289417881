export const randomId = (length = 6): string => {
  return Math.random()
    .toString(36)
    .substring(2, length + 2);
};

export const maskingString = (str: string, start: number, end: number): string => {
  if (!str || start < 0 || start >= str.length || end < 0 || end > str.length || start >= end) {
    return str;
  }

  const maskLength = end - start;
  const maskedStr = str.substring(0, start) + '*'.repeat(maskLength) + str.substring(end);
  return maskedStr;
};

export const checkPhoneNumberIsValid = (phoneNumber: string | null | undefined): boolean => {
  if (!phoneNumber) {
    return false;
  }

  // TODO: just only for KR phone number checking, need to enhance for other countries later
  if (phoneNumber.length < 10) {
    return false;
  }

  return true;
};

export const getOnlyNumberPhoneNumber = (phoneNumber: string): string => {
  return phoneNumber.replace(/[^0-9]/g, '');
};

export const maskEmail = (email: string, maskFirstNCharacters = 5): string => {
  const [localPart, domain] = email.split('@');
  const maskedPart = localPart
    .split('')
    .map((char, index) => (index < maskFirstNCharacters ? 'x' : char))
    .join('');

  return `${maskedPart}@${domain}`;
};

export const isValidPhoneString = (str: string): boolean => {
  if (!str) {
    return false;
  }
  return /^\+?\d+$/.test(str);

  // Test cases
  // console.log(isValidPhoneString("12345")); // true
  // console.log(isValidPhoneString("+65123456")); // true
  // console.log(isValidPhoneString("+82987654321")); // true
  // console.log(isValidPhoneString("+")); // false
  // console.log(isValidPhoneString("+abc123")); // false
  // console.log(isValidPhoneString("++12345")); // false
  // console.log(isValidPhoneString("12+345")); // false
  // console.log(isValidPhoneString("abc123")); // false
};
