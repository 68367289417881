/* eslint-disable @typescript-eslint/no-empty-function */
import { useChannel } from '@ably-labs/react-hooks';
import { Modal, Typography } from '@sicpama/core-components';
import { useTranslation } from 'react-i18next';
import { logger } from 'configs';
import { LOCAL_STORAGE_KEY } from 'constants/browser-storage.constant';
import { createContext, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { randomId } from 'utils/string';
import { STORE_ATTRIBUTE_NAME } from '../constants';
import { orderService } from '../services';
import Loader from '../components/loader';
import { useOrderStore, useThemeStore } from '../stores';
import { OrderStatus, PaymentOption } from '../submodules/sicpama-shared';
import { isPublicStoresPath } from 'utils/routing';

export interface RoutingManagementContextType {
  isAbleToReAddOrder: boolean;
  payingAllCustomer: string;
  setPayingAllCustomer: (value: string) => void;
}

export const RoutingManagementContext = createContext<RoutingManagementContextType>({
  isAbleToReAddOrder: false,
  payingAllCustomer: '',
  setPayingAllCustomer: () => {},
});

export function dinerTableReset(): void {
  localStorage.removeItem(LOCAL_STORAGE_KEY.MY_ID);
  localStorage.removeItem(LOCAL_STORAGE_KEY.MY_CURRENT_ORDER_ID);
  localStorage.removeItem(LOCAL_STORAGE_KEY.CURRENT_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_KEY.SID);
  localStorage.removeItem(LOCAL_STORAGE_KEY.MOVE_TO_PG_SIDE);
  sessionStorage.removeItem(LOCAL_STORAGE_KEY.SELECTED_CATEGORY_ID);
  sessionStorage.removeItem(LOCAL_STORAGE_KEY.VIEW_IN_GRID);
  localStorage.removeItem(LOCAL_STORAGE_KEY.MUST_FINISH_SPLIT_EVENLY);
  window.location.replace('/feedback');
}

export const RoutingManagementProvider = ({ children }: PropsWithChildren): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [firstDraftCheck, setFirstDraftCheck] = useState<boolean>(false);
  const [payingAllCustomer, setPayingAllCustomer] = useState('');
  const { pathname } = useLocation();

  const {
    myOrder,
    myInfo,
    tableOrders,
    postDraftOrdersHasSamePaymentOptionWithMe,
    ordersIncludingDraftRegardlessPaymentOption,
    getAllOrderItemsInTheSameTable,
  } = useOrderStore();
  const [isAbleToReAddOrder, setIsAbleToReAddOrder] = useState<boolean>(false);
  const { store } = useThemeStore();
  const { attributes } = store;
  const isPrintOncePaidAttribute = attributes?.find(
    (attribute) => attribute.name === STORE_ATTRIBUTE_NAME.PRINT_ONCE_PAID,
  );

  const isPrintOncePaid = isPrintOncePaidAttribute?.value === 'true';

  const sessionId = localStorage.getItem(LOCAL_STORAGE_KEY.SID) ?? randomId();

  useChannel(sessionId, 'clear-table', async () => {
    logger.info('CLEAR_TABLE_RECEIVED', {
      customerId: localStorage.getItem(LOCAL_STORAGE_KEY.MY_ID),
      orderId: localStorage.getItem(LOCAL_STORAGE_KEY.MY_CURRENT_ORDER_ID),
    });
    dinerTableReset();
  });

  useChannel(sessionId, 'kick-out', async (message) => {
    await getAllOrderItemsInTheSameTable();
    const orderId = message?.data?.orderId;
    if (orderId === localStorage.getItem(LOCAL_STORAGE_KEY.MY_CURRENT_ORDER_ID)) {
      // TODO: Refactor to common function later

      logger.info('KICK_OUT_RECEIVED', {
        customerId: localStorage.getItem(LOCAL_STORAGE_KEY.MY_ID),
        orderId: localStorage.getItem(LOCAL_STORAGE_KEY.MY_CURRENT_ORDER_ID),
      });
      dinerTableReset();
    }
  });

  useChannel(sessionId, 'cash-paid', async (message) => {
    const orderId = message?.data?.orderId;
    const paidAmount = message?.data?.paidAmount;
    await getAllOrderItemsInTheSameTable();

    if (orderId === localStorage.getItem(LOCAL_STORAGE_KEY.MY_CURRENT_ORDER_ID)) {
      logger.info('PAY_IN_STORE_PAID_RECEIVED', {
        orderId,
        paidAmount,
      });
    }
  });

  useChannel(sessionId, 'payment-callback-received', async () => {
    await getAllOrderItemsInTheSameTable();
  });

  useChannel(sessionId, 're-add-order', async (message) => {
    if (
      myInfo?.sicpamaId &&
      !isPrintOncePaid &&
      message.data.customerId !== localStorage.getItem('myId')
    ) {
      await orderService.reAddOrder();
      await getAllOrderItemsInTheSameTable();
      navigate('/menus');
    }
  });

  useEffect(() => {
    if (isPublicStoresPath(window.location.pathname)) {
      localStorage.clear();
      sessionStorage.clear();
    } else {
      if (myOrder.status === OrderStatus.DRAFT && !firstDraftCheck) {
        if (pathname !== '/tab' && myOrder.customer.sicpamaId) {
          navigate('/menus');
        }
        setFirstDraftCheck(true);
      }
    }
  }, [firstDraftCheck, myOrder]);

  useEffect(() => {
    if (myOrder?.paymentOptionEnum?.paymentOptionEnum === PaymentOption.SPIN_THE_WHEEL) {
      if (myOrder?.status === OrderStatus.WAITING) {
        if (!myOrder.paidByCustomerId) {
          navigate('/payment');
        } else {
          navigate('/spin-the-wheel');
        }
        return;
      }
      if (myOrder?.status === OrderStatus.DRAFT) {
        navigate('/spin-the-wheel');
        return;
      }
    }

    if (pathname !== '/payment' && myOrder.status === OrderStatus.WAITING) {
      navigate('/payment');
    }

    if (
      pathname === '/spin-the-wheel' &&
      myOrder.paymentOptionEnum?.paymentOptionEnum !== PaymentOption.SPIN_THE_WHEEL
    ) {
      navigate('/tab');
    }
  }, [pathname, myOrder]);

  useEffect(() => {
    // When diners who selected Split Evenly and they have already paid their part (*Split Evenly Paid Diners),
    // and are at Complete Order Page waiting for other Split Evenly companions.
    // If their Split Evenly companions have not finished the payment yet,
    // Split Evenly Paid Diners can only be at the Complete Order Page,
    // any intentional act like editing the url to go to other page, scan qr code again, ...
    // -> they still be redirected to Complete Order Page

    if (myOrder.status === OrderStatus.PAID || myOrder.status === OrderStatus.PRINTED) {
      if (isPrintOncePaid) {
        if (myOrder.paymentOptionEnum?.paymentOptionEnum === PaymentOption.ONE_OVER_N) {
          const filteredOrders = postDraftOrdersHasSamePaymentOptionWithMe.filter(
            (x) => x?.paymentGroupId === myOrder.paymentGroupId,
          );
          setIsAbleToReAddOrder(
            filteredOrders.length > 0 &&
              filteredOrders.every(
                (order) =>
                  order.status === OrderStatus.PAID || order.status === OrderStatus.PRINTED,
              ),
          );
        } else {
          setIsAbleToReAddOrder(true);
        }
      } else {
        setIsAbleToReAddOrder(
          ordersIncludingDraftRegardlessPaymentOption.length > 0 &&
            ordersIncludingDraftRegardlessPaymentOption.every(
              (order) => order.status === OrderStatus.PAID || order.status === OrderStatus.PRINTED,
            ),
        );
      }
      navigate('/customer-receipt');
    }
  }, [myOrder, tableOrders]);

  return (
    <RoutingManagementContext.Provider
      value={{ isAbleToReAddOrder, payingAllCustomer, setPayingAllCustomer }}
    >
      {children}
      <Modal
        opened={!!payingAllCustomer}
        onClose={() => {
          console.log('prevent close');
        }}
        centered
        size="sm"
      >
        <div className="flex flex-col items-center justify-center h-full">
          <Typography>
            {t('pages.search.wait')} {payingAllCustomer} {t('pages.search.paying')}
          </Typography>
          <Loader />
        </div>
      </Modal>
    </RoutingManagementContext.Provider>
  );
};
