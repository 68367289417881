import { useChannel } from '@ably-labs/react-hooks';
import { Button, Icon, Modal, Typography } from '@sicpama/core-components';
import { notifications } from '@sicpama/core-components/lib/notifications';
import { ANALYTICS_CSS_ID, LOCAL_STORAGE_KEY, PAYMENT_OPTIONS_CONFIG } from '../../constants';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OrderSelectors, useOrderStore } from 'stores';
import { useThemeStore } from '../../stores';
import ArrowProgressBar from '../arrow-progress-bar';
import Loader from '../loader';
import MyTopLeftAvatarProfile from './MyTopLeftAvatarProfile';

import './styles.scss';
import { PaymentOption } from '../../submodules/sicpama-shared';

interface ContainerProps extends React.PropsWithChildren {
  containerRef?: React.MutableRefObject<HTMLDivElement | null>;
  showSearch?: boolean;
  canSearch?: boolean;
  customClassName?: string;
  showArrowProgressBar?: boolean;
}

export const Container = ({
  children,
  containerRef,
  showSearch = false,
  canSearch = showSearch,
  customClassName = 'container-wrapper',
  showArrowProgressBar = true,
}: ContainerProps): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { getAllOrderItemsInTheSameTable, tableOrders } = useOrderStore();
  const myInfo = useOrderStore((state) => state.myInfo);
  const { store, theme } = useThemeStore();
  const [payingAllCustomer, setPayingAllCustomer] = useState('');
  const tableOrdersRef = useRef(tableOrders);

  useEffect(() => {
    if (!tableOrders.length) {
      getAllOrderItemsInTheSameTable();
    }
  }, []);

  useEffect(() => {
    tableOrdersRef.current = tableOrders;
  }, [tableOrders]);

  useEffect(() => {
    if (myInfo?.id) {
      window.dataLayer.push({ userId: myInfo?.id || 'unknown' });
    }
  }, [myInfo?.id]);

  const sessionId = localStorage.getItem(LOCAL_STORAGE_KEY.SID)!;

  useChannel(sessionId, 'add-to-tab', async (message) => {
    if (message.data.customerId !== localStorage.getItem('myId')) {
      notifications.show({
        withCloseButton: true,
        autoClose: 5000,
        message: t('notification.addMenuToTab', {
          customerName: message.data?.customerName,
          menuName: message.data?.menuName,
        }),
        icon: <Icon name="PlusCircle" color="white" />,
        color: 'green',
      });
    }
    await getAllOrderItemsInTheSameTable();
  });

  useChannel(sessionId, 'remove-from-tab', async (message) => {
    if (message.data.customerId !== localStorage.getItem('myId')) {
      notifications.show({
        withCloseButton: true,
        autoClose: 5000,
        message: t('notification.removeMenuFromTab', {
          customerName: message.data?.customerName,
          menuName: message.data?.menuName,
        }),
        icon: <Icon name="MinusCircle" color="white" />,
        color: 'red',
      });
    }
    await getAllOrderItemsInTheSameTable();
  });

  useChannel(sessionId, 'change-quantity', async () => {
    await getAllOrderItemsInTheSameTable();
  });

  useChannel(sessionId, 'back-to-tab', async () => {
    await getAllOrderItemsInTheSameTable();
    setPayingAllCustomer('');
  });

  useChannel(sessionId, 'checkout', async () => {
    await getAllOrderItemsInTheSameTable();
  });

  useChannel(sessionId, 'back-from-pg', async () => {
    await getAllOrderItemsInTheSameTable();
    setPayingAllCustomer('');
  });

  useChannel(sessionId, 'start-paying', async () => {
    await getAllOrderItemsInTheSameTable();
  });

  useChannel(sessionId, 're-add-order', async () => {
    await getAllOrderItemsInTheSameTable();
  });

  useChannel(sessionId, 'new-payment-success', async () => {
    await getAllOrderItemsInTheSameTable();
    setPayingAllCustomer('');
  });

  useChannel(sessionId, 'connecting-with-payment-gateway', async (message) => {
    const tableOrders = tableOrdersRef.current;
    if (!tableOrders.length) {
      return;
    }
    const payingOrderId = message.data.orderId;
    if (!payingOrderId) {
      return;
    }

    const payingOrder = tableOrders.find((order) => order.id === payingOrderId);
    if (
      payingOrder &&
      !payingOrder.isMyself &&
      payingOrder.paymentOptionEnum.paymentOptionEnum === PaymentOption.ALL
    ) {
      setPayingAllCustomer(payingOrder.customer.fullName);
    }
  });

  useChannel(sessionId, 'set-payment-option', async (message) => {
    await getAllOrderItemsInTheSameTable();
    const paymentOption = message.data.paymentOption as PaymentOption;
    const textMessage =
      paymentOption === PaymentOption.NOT_SELECTED
        ? t('pages.tab.unselectPaymentOption', {
            name: message.data.name,
          })
        : t('pages.tab.dinerHasSelectedPaymentOption', {
            name: message.data.name,
            paymentOption: t(PAYMENT_OPTIONS_CONFIG[paymentOption].label),
          });
    // If only me in the table, don't show notification for set payment option
    if (tableOrdersRef?.current?.length > 1) {
      notifications.show({
        withCloseButton: true,
        autoClose: 3000,
        message: textMessage,
        icon: <Icon name="Tick" color="white" />,
        color: 'green',
      });
    }
  });

  useChannel(sessionId, 'back-from-spin-the-wheel-to-tab', async (message) => {
    await getAllOrderItemsInTheSameTable();
  });

  useChannel(sessionId, 'join-table', async (message) => {
    await getAllOrderItemsInTheSameTable();
    const nbCustomer = OrderSelectors.getNumberOfCustomer(useOrderStore.getState());
    notifications.show({
      withCloseButton: true,
      autoClose: 4000,
      message: t('notification.joinInTheTable', {
        customerName: message?.data?.nickName || 'Guest',
        nbCustomer,
      }),
      color: 'green',
    });
  });

  useChannel(sessionId, 'kick-out', async (message) => {
    await getAllOrderItemsInTheSameTable();
  });

  return (
    <div
      className={customClassName}
      ref={(el) => {
        if (containerRef) {
          containerRef.current = el;
        }
      }}
    >
      {showSearch ? (
        <div className="flex gap-4 items-center px-4 py-2 w-full bg-white h-[56px] z-10 sticky top-0">
          {store?.foodCourtId !== null && (
            <Button
              className="bg-theme !h-8 !w-fit pl-1 pr-0"
              leftIcon={<Icon name="Home" color="white" style={{ marginLeft: 4 }} />}
              title=""
              titleColor="white"
              onClick={() => {
                // TODO: Quick temp solution for SendEat
                console.log('store: ', store); // for debug info
                if (
                  store.name.includes('센딧') ||
                  [10006, 10007, 10010, 10011].includes(store.id)
                ) {
                  // react-router-dom doesn't deal with external navigation.
                  // As you've seen it will only append the path target to the current base URL within the same domain.
                  // Use window.location.href to change location to an external URL
                  window.location.href = 'http://sendeat.ivyro.net';
                } else {
                  navigate(`/food-courts/${store.foodCourtId}`);
                }
              }}
              id={ANALYTICS_CSS_ID().MENU_PAGE.FOOD_COURT_HOME_PAGE_BTN}
            />
          )}
          <MyTopLeftAvatarProfile />
          {canSearch && (
            <button
              className="rounded-full items-center border border-theme text-gray-400 px-3 py-1 text-sm flex flex-row ml-auto w-full justify-between"
              onClick={() => {
                navigate('/search');
              }}
              id={ANALYTICS_CSS_ID().MENU_PAGE.SEARCH_INPUT}
            >
              <span className="mr-6 truncate">{t('pages.search.placeholder')}</span>
              <Icon name="Search" color={theme.color} />
            </button>
          )}
        </div>
      ) : null}
      {showArrowProgressBar && <ArrowProgressBar offsetTop={showSearch ? 56 : 0} />}
      {children}
      <Modal
        opened={!!payingAllCustomer}
        onClose={() => {
          console.log('prevent close');
        }}
        centered
        size="sm"
      >
        <div className="flex flex-col items-center justify-center h-full">
          <Typography>
            {t('pages.search.wait')} {payingAllCustomer} {t('pages.search.paying')}
          </Typography>
          <Loader />
        </div>
      </Modal>
    </div>
  );
};
