import { Icon, Input, Typography } from '@sicpama/core-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import ArrowProgressBar from '../arrow-progress-bar';

import './styles.scss';
import { useThemeStore } from '../../stores';
import { useTranslation } from 'react-i18next';
import { ROUTE } from 'constants/route.constant';
import { LOCAL_STORAGE_KEY } from 'constants/browser-storage.constant';
import { useEffect } from 'react';

export const PublicContainer = ({
  children,
  containerRef,
  showSearch = false,
  showProgressBar = true,
  customClassName = 'public-container-wrapper',
}: any): JSX.Element => {
  const { storeId, couponId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    theme: { color: themeColor },
    store,
    getStoreById,
  } = useThemeStore();

  const session = useSessionContext();

  const isCustomerLoggedIn = !session.loading && session.doesSessionExist;

  useEffect(() => {
    if ((!store || store?.id < 1) && storeId) {
      getStoreById(+storeId);
    }
  }, [store, storeId]);

  return (
    <div
      className={customClassName}
      ref={(el) => {
        if (containerRef) {
          containerRef.current = el;
        }
      }}
    >
      <div className="flex gap-4 items-center px-4 py-2 w-full bg-white h-[56px] z-10 sticky top-0">
        <div
          className="px-4 py-2 shadow rounded-3xl flex items-center w-1/3 justify-center border border-theme"
          onClick={() => {
            if (!isCustomerLoggedIn) {
              localStorage.setItem(LOCAL_STORAGE_KEY.BEFORE_SIGN_IN_PATH, window.location.pathname);
              navigate(`${ROUTE.LOGIN}?storeId=${storeId}`);
            }
          }}
        >
          {!couponId && <Icon name="Logout" color={themeColor} />}
          <Typography className="text-theme font-bold text-xs">
            {couponId ? store?.displayName : t('common.signUp')}
          </Typography>
        </div>
        <div
          className="ml-auto"
          onClick={() => {
            navigate(`/stores/${storeId}/search`);
          }}
        >
          <Input
            iconRight={<Icon name="Search" color="black" />}
            placeholder={t('pages.search.placeholder')!}
            borderRadius="24px"
          />
        </div>
      </div>
      {showProgressBar && <ArrowProgressBar offsetTop={showSearch ? 56 : 0} />}
      {children}
    </div>
  );
};
